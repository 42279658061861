import axios from "axios";

export const post_order = async (data) => {
    const url = `https://454ayndsx1.execute-api.us-east-1.amazonaws.com/Productive/concatct`;
    try {
      const response = await axios.post(url, data, {
        headers: {
        //   Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        //   "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };