import './App.css';
import { INDEX } from './pages';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

function App() {
  return (
    <Router>
    <Switch> 
        <Route path={"/"} exact component={INDEX}></Route>
        <Route path={"/home"} exact component={INDEX}></Route>
        <Redirect to="/"></Redirect>
    </Switch>
</Router>
  );
}

export default App;
