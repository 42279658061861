import React, { useState } from 'react';
import logofc from "../../images/fricongelados.png";
import "./navbar.css";
import { TERMS } from '../../tools/terms/terms';
import { CustomDialog } from "react-st-modal";
import MenuIcon from '@mui/icons-material/Menu';

export const NAVAR = () => {

  const [open, setopen]=useState(false);


  return (
    <>
    <div className='navbar-container'>
        <div className='navbar-content'>
            <div className='navbar-grid'>
                <div className='navbar-secction-icons'>
                    <img src={logofc} alt='icono-fc'></img>
                </div>
                <div className='navbar-menu-items'>
                    <ul className='navbar-ul'>
                        <li onClick={()=>window.location="https://proveedores.fricongelados.com/"} translate="no" >PROVEEDORES</li>
                        <li onClick={()=>window.location="https://od2.fricongelados.com/"} translate="no">USUARIOS</li>
                        <li onClick={()=>window.location="#contact-container"} translate="no">CONTACTO</li>
                        <li onClick={async()=>{
                            const result = await CustomDialog(
                                <TERMS/>,
                                {
                                  className: "custom-modal",
                                  title: "   ",
                                  showCloseIcon: true,
                                  isCanClose:false,
                                }
                              ); 
                        }} translate="no">AVISO DE PRIVACIDAD</li>
                    </ul>
                    <MenuIcon onClick={()=>setopen(!open)}></MenuIcon>
                </div>
            </div>
        </div>  
    </div>
    {
          open===true?
          <div className='menu-items-sections'>
            <div className='cap-black'></div>
          <div className=''>
            <ul className='navbar-ul-phone'>
                        <li onClick={()=>window.location="https://proveedores.fricongelados.com/"} translate="no">PROVEEDORES</li>
                        <li onClick={()=>window.location="https://od2.fricongelados.com/"} translate="no">USUARIOS</li>
                        <li onClick={()=>window.location="#contact-container"} translate="no">CONTACTO</li>
                        <li onClick={async()=>{
                            const result = await CustomDialog(
                                <TERMS/>,
                                {
                                  className: "custom-modal",
                                  title: "   ",
                                  showCloseIcon: true,
                                  isCanClose:false,
                                }
                              ); 
                        }} translate="no">AVISO DE PRIVACIDAD</li>
                    </ul>
          </div>
        </div>:<></>
        }
    </>
    
  )
}
